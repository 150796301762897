<template>
  <div class="teachingManagement">
    <div class="flex-start">
      <div class="flex-left">
        <el-button size="mini"
                   icon="el-icon-plus"
                   style="margin-left: 10px;margin-bottom:10px;"
                   @click="addGroup">新增
        </el-button>

        <el-tree
          :data="folderList"
          :highlight-current="true"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          :render-content="renderContent">
        </el-tree>

        <el-dialog
          title="提示"
          :visible.sync="teachingPlanDialogVisible"
          width="450px"
          >
          <el-form :model="teachingPlanForm" :rules="teachingPlanFormRules" ref="teachingPlanFormRef" label-width="100px" class="demo-ruleForm">
            <el-form-item label="名称" prop="label">
              <el-input v-model="teachingPlanForm.label"></el-input>
            </el-form-item>
          </el-form>
          <!-- <span>这是一段信息</span> -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="teachingPlanDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addTeachingPlanConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>

      <div class="flex-right">
        <div class="flex-box">
          <div class="left">
            <el-button type="success"
                      @click="addTeachingManagement">+新增</el-button>
            <el-button type="info">下载</el-button>
          </div>
          <div class="flex-box">
            <el-select class="picker"
                      clearable
                      v-model="query.gradeid"
                      filterable
                      placeholder="请选择年级">
              <el-option v-for="item in gradeList"
                        :key="item.id"
                        :label="item.gname"
                        :value="item.id">
              </el-option>
            </el-select>
            <el-select class="picker"
                      clearable
                      v-model="query.courseid"
                      filterable
                      placeholder="请选择学科">
              <el-option v-for="item in courseList"
                        :key="item.id"
                        :label="item.course"
                        :value="item.id">
              </el-option>
            </el-select>
            <el-input placeholder="教师姓名"
                      class="picker"
                      v-model="query.name"></el-input>
            <el-input placeholder="关键字"
                      class="picker"
                      v-model="query.content"></el-input>

            <!-- <classSelector></classSelector> -->

            <!-- 学年？ -->

            <el-button type="primary"
                      class="picker"
                      @click="loadTeachingManagement(1,folder_id)">查 询</el-button>
          </div>
        </div>
        
        <el-table :data="teachingManagementTable"
                  style="width: 100%">
          <el-table-column
            label="文件夹"
            prop="folder"
            align="center"
          ></el-table-column>
          <el-table-column label="文件标题"
                          prop="title"
                          align="center"></el-table-column>
          <el-table-column label="上传教师"
                          prop="opername"
                          align="center"></el-table-column>
          <el-table-column label="学科"
                          prop="course"
                          align="center"></el-table-column>
          <el-table-column label="使用对象"
                          prop="gname"
                          align="center"></el-table-column>
          <el-table-column label="关键词"
                          prop="content"
                          align="center"></el-table-column>
          <el-table-column label="备注"
                          prop="remark"
                          align="center"></el-table-column>
          <el-table-column label="操作"
                          width="270px"
                          prop="course"
                          align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary"
                        @click="editTeachingManagement(scope.row)">编辑
              </el-button>
              <el-button size="mini" type="primary"
                        @click="downloadTeachingManagement(scope.row)">移动
              </el-button>
              <el-button size="mini" type="danger"
                        @click="deleteTeachingManagement(scope.row.id)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination layout="prev,pager,next"
                      background
                      :page-count="page.pageCount"
                      :page-size="page.currentPage"
                      @current-change="loadTeachingManagement"></el-pagination>

        <el-dialog :title="addTeachingManagementForm.id ? '编辑' : '新增'"
                  :visible.sync="addTeachingManagementFormDialogVisible"
                  width="600px">
          <div class="center">
            <el-form :model="addTeachingManagementForm"
                    ref="addTeachingManagementFormRef"
                    label-width="100px"
                    :rules="addTeachingManagementFormRules">
              <!-- 名称 -->
              <el-form-item label="文件标题："
                            prop="title">
                <el-input v-model="addTeachingManagementForm.title"
                          placeholder="请输入标题"></el-input>
              </el-form-item>
              <!-- 班级 -->
              <el-form-item label="学科："
                            prop="courseid">
                <el-select v-model="addTeachingManagementForm.courseid"
                          filterable
                          placeholder="请选择学科">
                  <el-option v-for="item in courseList"
                            :key="item.id"
                            :label="item.course"
                            :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 使用对象 -->
              <el-form-item label="使用对象："
                            prop="gradeid">
                <el-select v-model="addTeachingManagementForm.gradeid"
                          filterable
                          placeholder="请选择年级">
                  <el-option v-for="item in gradeList"
                            :key="item.id"
                            :label="item.gname"
                            :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 关键词 -->
              <el-form-item label="关键词："
                            prop="content">
                <el-input v-model="addTeachingManagementForm.content"
                          placeholder="请输入关键字"></el-input>
              </el-form-item>
              <el-form-item label="附件：">
                <el-upload
                  class="upload-demo"
                  list-type="picture"
                  action="/api/base/uploadByOss.do"
                  :headers="{Authorization:token}"
                  :limit="1"
                  :on-remove="onRemoveFile"
                  ref="upload"
                  :on-success="enclosureUploadSuccess"
                  :data="{path:'teachingManagement',name:''}"
                  :show-file-list="true">

                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" v-if="addTeachingManagementForm.url" class="el-upload__tip">已上传附件，可下载附件，也可重新上传附件</div>
                </el-upload>
                <el-button size="small" type="primary"
                        class="load-file"
                        @click="loadFile"
                        v-if="addTeachingManagementForm.url">下载附件</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- 确认与取消 -->
          <span slot="footer"
                class="dialog-footer">
            <el-button @click="addTeachingManagementFormDialogVisible = false">取 消</el-button>
            <el-button type="primary"
                      @click="teachingManagementConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>

    </div>

  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";

export default {
  components: { classSelector, termSelector },
  name: "teachingManagement",
  data() {
    return {
      token: sessionStorage.getItem("token"),
      page: { pageCount: 1, currentPage: 0 },
      teachingManagementTable: [],
      addTeachingManagementFormDialogVisible: false,
      addTeachingManagementForm: {
        id: "",
        title: "",
        content: "",
        courseid: "",
        gradeid: "",
        url: "",
        folder_id:''
      },
      addTeachingManagementFormRules: {
        title: [{ required: true }],
        content: [{ required: true }],
        courseid: [{ required: true }],
        gradeid: [{ required: true }],
        // url: [{ required: true }],
      },
      courseList: [],
      gradeList: [],
      query: {
        gradeid: "",
        courseid: "",
        name: "",
        content: "",
      },
      // 文件夹列表
      folderList:[],
      teachingPlanDialogVisible:false,
      // 添加表单对象
      teachingPlanForm:{
        label:'',
        type:'',
        pid:'',
        tag:''
      },
      // 表单验证规则对象
      teachingPlanFormRules:{
        label:[{required:true, message:'请输入名称', trigger:'blur'}],
        // type:[{required:true, message:'请输入名称', trigger:'blur'}],
      },
      folder_id:''
    };
  },
  created() {
    // 查询文件夹
    // /base/queryLessonPlanFolder.do
    this.queryFolder()

    // 查询学科
    this.querySubject()
    // 查询年级
    this.queryGrade()

    // this.getCourseListAndGradeList();
    this.loadTeachingManagement(1);
  },
  methods: {
    enclosureUploadSuccess(res, file) {
      this.addTeachingManagementForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.addTeachingManagementForm.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.addTeachingManagementForm.url = "";
    },
    // 查询学科
    querySubject(){
      this.$post(`/media/queryCourse.do`)
      .then(res=>{
        this.courseList = res.data
      })
    },
    // 查询年级
    queryGrade(){
      this.$post(`/media/queryAllclasses.do`)
      .then(res=>{
        this.gradeList = res.data
        console.log(this.gradeList)
      })
    },
    addGroup(){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.teachingPlanFormRef.resetFields()
        this.teachingPlanForm.pid = 0
        this.teachingPlanForm.type = 1
        this.teachingPlanForm.tag = 1
      })

    },
    // 查询文件夹
    queryFolder(){
      this.$post(`/base/queryLessonPlanFolder.do`,{
        type:1,
      })
      .then(res=>{
        console.log(res)
        this.folderList = res.data
      })
    },
    renderContent(h, { node, data, store }){
      return (
        <span on-click={() => this.clickTeachingPlan(data)}>
          <span>{node.label}</span>
          <span class="teachingPlan">
            <el-link
              icon="el-icon-plus"
              type="success"
              style="margin-left:15px"
              on-click={() => this.addTeachingPlan(data)}
            ></el-link>
            <el-link
              icon="el-icon-edit"
              type="primary"
              style="margin-left:15px"
              on-click={() => this.editTeachingPlan(data)}
            ></el-link>
            <el-link
              icon="el-icon-delete"
              type="danger"
              style="margin-left:15px"
              on-click={() => this.deleteTeachingPlan(data)}
            ></el-link>
          </span>
        </span>
      )
    },
    clickTeachingPlan(data){
      // console.log(data.id)
      this.loadTeachingManagement(1,data.id)
      this.addTeachingManagementForm.folder_id = data.id
    },
    // 添加
    addTeachingPlan(data){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.teachingPlanFormRef.resetFields()
        this.teachingPlanForm.pid = data.id
        this.teachingPlanForm.type = data.type
        this.teachingPlanForm.tag = 1
      })
    },
    // 编辑
    editTeachingPlan(data){
      this.teachingPlanDialogVisible = true
      this.$nextTick(()=>{
        this.teachingPlanForm.label = data.label
        this.teachingPlanForm.type = data.type
        this.teachingPlanForm.pid = data.id
        this.teachingPlanForm.tag = 2
      })
    },
    // 提交
    addTeachingPlanConfirm(){
      console.log(1)
      // return
      let data
      let url
      console.log(this.teachingPlanForm.tag)

      if(this.teachingPlanForm.tag === 1){
        data = {
          label:this.teachingPlanForm.label,
          type:this.teachingPlanForm.type,
          pid:this.teachingPlanForm.pid,
        }
        url = `/base/insertLessonPlanFolder.do`
        console.log('添加')
      }else if(this.teachingPlanForm.tag === 2){
        data = {
          id:this.teachingPlanForm.pid,
          label:this.teachingPlanForm.label,
        }
        url = `/base/updateLessonPlanFolder.do`
        console.log('编辑')
      }
      this.$post(url,data)
      .then(res=>{
        console.log(res)
        if(this.teachingPlanForm.tag === 1){
          this.$message({
            type:'success',
            message:'添加成功！'
          })
          // this.queryFolder(1,this.folder_id)
        }else{
          this.$message({
            type:'success',
            message:'编辑成功！'
          })
          // this.queryFolder(this.page.currentPage,this.folder_id)
        }
        this.queryFolder()
        this.teachingPlanDialogVisible = false
      })
      .catch(err=>{
        if(this.teachingPlanForm.tag === 1){
          this.$message({
            type:'danger',
            message:'添加失败！'
          })
        }else{
          this.$message({
            type:'danger',
            message:'编辑失败！'
          })
        }
      })
    },
    // 删除
    deleteTeachingPlan(data){
      this.$confirm('是否删除该条记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$post(`/base/deleteLessonPlanFolder.do`,{
            id:data.id,
            sid:data.sid,
            type:data.type
          })
          .then(res=>{
            this.queryFolder()
            this.$message({
              type:'success',
              message:'删除成功！'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    
    },
    // getCourseListAndGradeList() {
    //   this.courseList = JSON.parse(sessionStorage.getItem("userInfo")).courses;
    //   this.gradeList = JSON.parse(sessionStorage.getItem("userInfo")).gradeList;
    //   console.log(this.courseList);
    // },
    // 获取初始页面数据
    loadTeachingManagement(page,id) {
      this.folder_id = id
      const that = this;
      let data = {
        indexNo: page,
        gradeid: this.query.gradeid,
        courseid: this.query.courseid,
        name: this.query.name,
        content: this.query.content,
        type: 1, //1是教案
        // folder_id:id,
        folder_id:this.folder_id,
      };

      this.$post("/base/queryLessonPlan.do", data)
        .then((res) => {
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
          that.teachingManagementTable = res.data.rows;
          console.log(that.teachingManagementTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 添加
    addTeachingManagement() {
      console.log(this.folder_id)

      if(this.folder_id == undefined){
        this.$message({
          type:'info',
          message:'请先选择文件夹！'
        })
        return
      }

      this.addTeachingManagementFormDialogVisible = true;
      this.$nextTick(() => {
        // this.$refs.addTeachingManagementFormRef.resetFields();
        this.addTeachingManagementForm.id = "";
        this.addTeachingManagementForm.title = '';
        this.addTeachingManagementForm.content = '';
        this.addTeachingManagementForm.courseid = '';
        this.addTeachingManagementForm.gradeid = '';
        this.addTeachingManagementForm.url = '';
        this.addTeachingManagementForm.folder_id = this.folder_id
        this.$refs.upload.clearFiles();
        this.addTeachingManagementForm.url = ''
      });
    },
    // 编辑
    editTeachingManagement(row) {
      // 显示对话框
      this.addTeachingManagementFormDialogVisible = true;
      // 把数据赋值给编辑表单
      this.$nextTick(() => {
        this.addTeachingManagementForm.id = row.id;
        this.addTeachingManagementForm.title = row.title;
        this.addTeachingManagementForm.content = row.content;
        this.addTeachingManagementForm.courseid = row.courseid;
        this.addTeachingManagementForm.url = row.url;
        this.addTeachingManagementForm.gradeid = row.gradeid;
        this.$refs.upload.clearFiles();
      });
    },
    // 表单提交
    teachingManagementConfirm() {
      this.$refs.addTeachingManagementFormRef.validate((validate) => {
        if (validate) {
          // 编辑审批人表单提交
          if (this.addTeachingManagementForm.id) {
            this.$post(
              "/base/updateLessonPlan.do",
              this.addTeachingManagementForm
            ).then((res) => {
              this.$message.success("编辑成功");
              this.addTeachingManagementFormDialogVisible = false;
              // 更新当前页数据列表
              this.loadTeachingManagement(this.page.currentPage,this.folder_id);
            });
            return;
          }
          // 添加审批人表单提交
          const data = {
            title: this.addTeachingManagementForm.title,
            content: this.addTeachingManagementForm.content,
            courseid: this.addTeachingManagementForm.courseid,
            gradeid: this.addTeachingManagementForm.gradeid,
            url: this.addTeachingManagementForm.url,
            type:1,
            folder_id:this.addTeachingManagementForm.folder_id,
          };
          console.log(data);
          this.$post("/base/insertLessonPlan.do", data)
            .then((res) => {
              this.$message.success("添加成功");
              this.addTeachingManagementFormDialogVisible = false;
              this.loadTeachingManagement(1,this.folder_id);
              console.log(res);
            })
            .catch((err) => {
              // that.$message.error(err.message.data);
            });
        }
      });
    },
    // 删除
    deleteTeachingManagement(id) {
      const that = this;
      this.$confirm("是否删除教案管理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteLessonPlan.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadTeachingManagement(this.page.currentPage,this.folder_id);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style>
.teachingPlan{
  display: none;
}

.el-tree-node__content:hover .teachingPlan {
  display: inline;
}

</style>

<style scoped>
.input-box {
  width: 180px;
  /* 180 */
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.display{
  display: inline-block;
}

.flex-left{
  width: 20%;
  margin-top:20px;
}

.flex-right{
  width: 80%;
}



</style>
